var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('kas-modal', {
    on: {
      "submitKas": _vm.approve
    }
  }), _vm.canConfirm ? _c('div', {
    staticClass: "d-flex justify-content-end mb-2"
  }, [_vm.penjualan.selesai == 0 ? _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "success",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openKasModal($event);
      }
    }
  }, [_vm._v("Pilih Sumber Pendapatan")]) : _vm._e(), _vm.penjualan.selesai == 1 ? _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "outline-danger",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmCancel($event);
      }
    }
  }, [_vm._v("Tolak")]) : _vm._e()], 1) : _vm._e(), _c('b-card-actions', {
    staticClass: "mb-2",
    attrs: {
      "title": _vm.penjualan ? _vm.penjualan.no : '-',
      "action-collapse": ""
    }
  }, [_c('b-row', {
    staticClass: "justify-content-between align-items-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "8",
      "lg": "10"
    }
  }, [_c('table', [_c('tr', [_c('td', [_vm._v("Nama")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.penjualan.nama))])]), _c('tr', [_c('td', [_vm._v("Tanggal")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.penjualan.tanggal))])]), _c('tr', [_c('td', [_vm._v("Keterangan")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.penjualan.keterangan))])]), _c('tr', [_c('td', [_vm._v("Status")]), _c('td', [_vm._v(":")]), _c('th', [_vm.penjualan.selesai == 0 ? _c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" Belum Selesai ")]) : _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Selesai ")])], 1)])])]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "2"
    }
  }, [_c('small', {
    staticClass: "d-block text-center"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('h4', {
    staticClass: "text-danger"
  }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalRincian)))])])])])], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.allowCreate() ? _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openModalAsset($event);
      }
    }
  }, [_vm._v(" Pilih Aset ")]) : _vm._e(), _c('b-card', {
    attrs: {
      "title": "Rincian Aset ".concat(_vm.selectedPenyimpanans.length > 0 ? '(' + _vm.selectedPenyimpanans.length + ')' : '')
    }
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.selectedPenyimpanans
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.asset ? _c('span', [_vm._v(_vm._s(item.asset.nama))]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Aset tidak ditemukan")])];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(item.harga_jual)) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.asset && item.asset.satuan ? _c('span', [_vm._v(" " + _vm._s(item.asset.satuan.satuan) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(item.id_satuan))])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref5) {
        var item = _ref5.item,
          index = _ref5.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "size": "24"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item, index);
            }
          }
        })];
      }
    }])
  })], 1)], 1), _vm.allowCreate() ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.selectedPenyimpanans.length < 1 || _vm.penjualan.selesai == 1
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)]) : _vm._e()], 1), _c('modal-penyimpanan', {
    on: {
      "chooseAsset": _vm.onChooseAsset
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }