<template>
    <b-modal no-close-on-backdrop no-close-on-esc id="modal-penyimpanan" size="xl">
        <b-row>
            <b-col sm="12" md="6" class="mb-2">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6" class="mb-2">
                <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        />
                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!-- / -->

            <b-col cols="12">
                <b-table responsive :filter="filter" :per-page="perPage" :current-page="currentPage" :fields="fields" :items="penyimpanans">
                    <template #cell(checkbox)="{item}">
                        <b-form-checkbox v-if="item.stok > 0" v-model="selectedPenyimpanans" :value="item"></b-form-checkbox>
                        <feather-icon v-else icon="SlashIcon" class="text-danger"></feather-icon>
                    </template>
                    <template #cell(no)="{index}">
                        {{++index}}
                    </template>
                    <template #cell(jenis)="{item}">
                        <div v-if="item.asset">
                            {{ item.asset.jenis == 1 ? 'TETAP' : 'BPH' }}
                        </div>
                    </template>
                    <template #cell(nama)="{item}">
                        {{ item.asset ? item.asset.nama : '' }}
                    </template>
                    <template #cell(kode)="{item}">
                        {{ item.asset ? item.asset.kode : '' }}
                    </template>
                    <template #cell(harga_jual)="{item, index}">
                        <b-form-input v-model="item.harga_jual" @keyup="doFormatRupiah(index)"></b-form-input>
                    </template>
                    <template #cell(stok)="{item}">
                        <b-form-input v-if="item.stok > 0" v-model="item.set_stok"></b-form-input>
                        <span v-else>{{item.stok}}</span>
                    </template>
                    <template #cell(satuan)="{item}">
                        {{ item.asset && item.asset.satuan ? item.asset.satuan.satuan : '' }}
                    </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    size="sm"
                    class="my-0"
                />
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button class="mb-2" variant="primary" @click.prevent="submit">Pilih Aset</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BButton, BModal, BRow, BCol, BPagination, BTable, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'
export default {
    components: {
        BButton, BModal, BRow, BCol, BPagination, BTable, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend
    },
    methods: {
        doFormatRupiah(index) {
            this.penyimpanans[index].harga_jual = this.formatRupiah(this.penyimpanans[index].harga_jual)
        },
        submit() {
            if(this.selectedPenyimpanans.length < 1) {
                this.displayError({
                    message: 'Harap pilih stok aset!'
                })
                return false
            }

            const payloads = []
            this.selectedPenyimpanans.map(item => {
                if(item.asset) {
                    const payload = {
                        asset: item.asset,
                        asset_id: item.asset.id,
                        harga_jual: this.unFormatRupiah(item.harga_jual),
                        id_satuan: item.asset.satuan ? item.asset.satuan.id : null,
                        jumlah: item.set_stok,
                        penjualan_id: this.$route.params.id,
                        penyimpanan_id: item.id
                    }
                    payloads.push(payload)
                }
            })

            this.$emit('chooseAsset', payloads)
        }
    },
    data: () => ({
        selectedPenyimpanans: [],
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        fields: [
        {key: 'checkbox', label: '#'},
            {key: 'jenis', label: 'Jenis'},
            {key: 'nama', label: 'Nama Aset'},
            {key: 'kode', label: 'Kode'},
            {key: 'harga_jual', label: 'Harga Jual'},
            {key: 'stok', label: 'Stok'},
            {key: 'satuan', label: 'Satuan'},
            {key: 'actions', label: '#'}
        ]
    }),
    computed: {
        penyimpanans() {
            const penyimpanans = JSON.parse( JSON.stringify(this.$store.state.asset.stocks) )
            const availablePenyimpanan = []
            penyimpanans.map(item => {
                if(item.asset) {
                    item.set_stok = item.stok
                    item.harga_jual = item.asset.harga_dasar

                    availablePenyimpanan.push(item)
                }
            })
            return availablePenyimpanan
        }
    }
}
</script>